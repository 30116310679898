import React, { Component } from 'react';
import Layout from '../components/layout';
import HelmetEx from '../components/helmet-ex';
import Article from '../components/article';
import { Link } from 'gatsby';
import Config from '../config';

export default class Products extends Component {
    render() {
        const routes = Config.Services.ProductRoutes;
        return (
            <Layout>
                <div className="products-page">
                    <HelmetEx>
                        <div metadata="title">Viktoria Interior | Unique fabrics, finishes, and decorative details | Elmwood Park, NJ</div>
                        <div metadata="keywords">viktoria,interior,unique,fabrics,finishes,decorative details,bergen county,NJ</div>
                        <div metadata="description">
                            Realize your vision for a beautiful home with our variety of fine decorative fabrics
                        </div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h1>Products</h1>
                                            <h3 className="text-center">Unique fabrics, finishes, and decorative details distinguish</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="articles">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <Article >
                                        <h3>FEATURED PRODUCTS</h3>
                                        <p>
                                            Unique fabrics, finishes, and decorative
                                            details distinguish the exclusive...
                                        </p>
                                        <Link to={routes.featuredProducts} className="link-discover-now">Discover Now</Link>
                                    </Article>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <Article>
                                        <h3>WINDOW FASHION</h3>
                                        <p>
                                            We proudly carry Hunter Douglas Blinds,
                                            which offer the industry’s largest selection
                                            of blinds made of...
                                        </p>
                                        <Link to={routes.hunterDouglasWindowFashions} className="link-discover-now">Discover Now</Link>
                                    </Article>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <Article>
                                        <h3>BED, BATH AND KITCHEN</h3>
                                        <p>
                                            We can help you select the perfect bedding and bedspreads,
                                            so you have sweet dreams every night...
                                        </p>
                                        <Link to={routes.bedBathAndKitchen} className="link-discover-now">Discover Now</Link>
                                    </Article>
                                </div>
                            </div>
                            <div className="row pt-5">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <Article>
                                        <h3>HOME DECOR</h3>
                                        <p>
                                            Realize your vision for a beautiful home with our variety of fine
                                            decorative fabrics for draperies, valances, bedspreads...
                                        </p>
                                        <Link to={routes.homeDecor} className="link-discover-now">Discover Now</Link>
                                    </Article>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <Article>
                                        <h3>PATIO AWNINGS, SHADES AND DRAPERS</h3>
                                        <p>
                                            Consider a porch shade so you can enjoy your porch,
                                            patio or family room all day long - even during the...
                                        </p>
                                        <Link to={routes.patioAwningsShadesAndDrapers} className="link-discover-now">Discover Now</Link>
                                    </Article>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <Article>
                                        <h3>WINDOW TREATMENTS</h3>
                                        <p>
                                            Add a touch of beauty over any window
                                            covering or hide room imperfections
                                            with a custom cornice...
                                        </p>
                                        <Link to={routes.windowTreatments} className="link-discover-now">Discover Now</Link>
                                    </Article>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout >
        )
    }
}   