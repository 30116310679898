import React from 'react';

const Article = (props) => (
    <div className="article-component">
        <div className="row mr-0 ml-0 ">
            {props.children}
        </div>
    </div>
)

export default Article;